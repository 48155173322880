import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faUser,
  faHome,
  faSearch,
  faExclamationCircle,
  faTools,
  faEllipsisV,
  faTable,
  faGlobe,
  faWindowClose,
  faChartBar,
  faChartLine,
  faBriefcase,
  faUsersCog,
  faCogs,
  faStoreAlt,
  faSnowflake,
  faCaravan,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon, Sidebar } from "ui-kit-ck-consultant";
import Icon from "../images/pilotagereseau.svg";

import AuthContext from "../context/AuthContext";

import axiosConfig from "../utils/axiosConfig";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarReduce: false,
      sidebarMobile: window.innerWidth < 640,
      views: [
        {
          icon: <FontAwesomeIcon icon={faHome} />,
          name: "Tableau de bord",
          path: "/home",
        },
        {
          icon: <FontAwesomeIcon icon={faUsersCog} />,
          name: "Administration",
          disabled: !this.props.admin && this.props.companyId,
          views: [
            {
              icon: <FontAwesomeIcon icon={faCogs} />,
              name: "Général",
              path: "/admin-general",
              disabled: !this.props.admin && this.props.companyId,
            },
            {
              icon: <FontAwesomeIcon icon={faUser} />,
              name: "Utilisateurs",
              path: "/admin-user",
              disabled: !this.props.admin && this.props.companyId,
            },
            {
              icon: <FontAwesomeIcon icon={faTools} />,
              name: "Réparateurs",
              path: "/admin-garage",
              disabled: !this.props.admin && this.props.companyId,
            },
            {
              icon: <FontAwesomeIcon icon={faStoreAlt} />,
              name: "Experts",
              path: "/admin-expert",
              disabled: !this.props.admin && this.props.companyId,
            },
          ],
        },
        {
          icon: <FontAwesomeIcon icon={faChartBar} />,
          name: "Statistiques",
          disabled:
            !this.props.access.clickable.statisticsGeneral &&
            !this.props.access.clickable.statisticsAbeille &&
            !this.props.access.clickable.statisticsAllianz &&
            !this.props.access.clickable.statisticsAllianzOM &&
            !this.props.access.clickable.statisticsAxa &&
            !this.props.access.clickable.glass &&
            !this.props.access.clickable.selfcare &&
            !this.props.access.clickable.caravaning &&
            !this.props.access.clickable.electric &&
            !this.props.access.clickable.ranking &&
            !this.props.access.clickable.ega &&
            !this.props.access.clickable.statisticsGarage,
          views: [
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Spécifiques",
              path: "/statistics-1",
              disabled: !this.props.access.clickable.statisticsAbeille,
            },
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Spécifiques",
              path: "/statistics-2",
              disabled: !this.props.access.clickable.statisticsAllianz,
            },
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Spécifiques",
              path: "/statistics-3",
              disabled: !this.props.access.clickable.statisticsAxa,
            },
            {
              icon: <FontAwesomeIcon icon={faWindowClose} />,
              name: "Bris de glace",
              path: "/broken-glass",
              disabled: !this.props.access.clickable.glass,
            },
            {
              icon: <FontAwesomeIcon icon={faChartLine} />,
              name: "Selfcare",
              path: "/selfcare",
              disabled: !this.props.access.clickable.selfcare,
            },
            {
              icon: <FontAwesomeIcon icon={faSnowflake} />,
              name: "EGA",
              disabled: !this.props.access.clickable.ega,
              path: "/EGA",
            },
            {
              icon: <FontAwesomeIcon icon={faCaravan} />,
              name: "Caravaning",
              disabled: !this.props.access.clickable.caravaning,
              path: "/caravaning",
            },
            {
              icon: <FontAwesomeIcon icon={faBolt} />,
              name: "Électrique",
              disabled: !this.props.access.clickable.electric,
              path: "/electric",
            },
            {
              icon: <FontAwesomeIcon icon={faBolt} />,
              name: "Classement",
              disabled: !this.props.access.clickable.ranking,
              path: "/ranking",
            },
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Spécifiques",
              disabled: !this.props.access.clickable.statisticsCovea,
              path: "/statistics-4",
            },
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Générales",
              path: "/statistics-5",
              disabled: !this.props.access.clickable.statisticsGeneral,
            },
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Spécifiques",
              path: "/statistics-AOM",
              disabled: !this.props.access.clickable.statisticsAllianzOM,
            },
            {
              icon: <FontAwesomeIcon icon={faTools} />,
              name: "Suivi réparateurs",
              path: "/statistics-garage",
              disabled: !this.props.access.clickable.statisticsGarage,
            },
          ],
        },
        {
          icon: <FontAwesomeIcon icon={faSearch} />,
          name: "Audit",
          disabled: !this.props.access.clickable.audit,
          path: "/audit",
        },
        {
          icon: <FontAwesomeIcon icon={faExclamationCircle} />,
          name: "Anomalie",
          disabled: !this.props.access.clickable.anomaly,
          path: "/anomaly",
        },
        {
          icon: <FontAwesomeIcon icon={faBriefcase} />,
          name: "Répartition missions",
          disabled: !this.props.access.clickable.missions,
          views: [
            {
              icon: <FontAwesomeIcon icon={faTable} />,
              name: "Tableau",
              path: "/missions-table",
              disabled: !this.props.access.clickable.missions,
            },
            {
              icon: <FontAwesomeIcon icon={faGlobe} />,
              name: "Carte",
              path: "/missions-map",
              disabled: !this.props.access.clickable.missions,
            },
          ],
        },
      ],
      logo: null,
    };
    this.sidebarRef = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getCompanyIcon();

    const displayed = this.props.access.displayed;
    if (this.sidebarRef.current) {
      let views = this.state.views;
      if (!displayed.missions) {
        views = [...views.slice(0, 5), ...views.slice(6)];
      }
      if (!displayed.anomaly) {
        views = [...views.slice(0, 4), ...views.slice(5)];
      }
      if (!displayed.audit) {
        views = [...views.slice(0, 3), ...views.slice(4)];
      }
      if (
        !displayed.statisticsAllianz &&
        !displayed.statisticsAllianzOM &&
        !displayed.statisticsGeneral &&
        !displayed.statisticsAbeille &&
        !displayed.statisticsAxa &&
        !displayed.selfcare &&
        !displayed.glass &&
        !displayed.caravaning &&
        !displayed.electric &&
        !displayed.ranking &&
        !displayed.ega &&
        !displayed.statisticsGarage
      ) {
        views = [...views.slice(0, 2), ...views.slice(3)];
      } else {
        console.log( views[2].views);
        if (!displayed.statisticsGarage) {
          views[2].views.splice(12, 1);
        }
        if (!displayed.statisticsAllianzOM) {
          views[2].views.splice(11, 1);
        }
        if (!displayed.statisticsGeneral) {
          views[2].views.splice(10, 1);
        }
        if (!displayed.statisticsCovea) {
          views[2].views.splice(9, 1);
        }
        if (!displayed.ranking) {
          views[2].views.splice(8, 1);
        }
        if (!displayed.electric) {
          views[2].views.splice(7, 1);
        }
        if (!displayed.caravaning) {
          views[2].views.splice(6, 1);
        }
        if (!displayed.ega) {
          views[2].views.splice(5, 1);
        }
        if (!displayed.selfcare) {
          views[2].views.splice(4, 1);
        }
        if (!displayed.glass) {
          views[2].views.splice(3, 1);
        }
        if (!displayed.statisticsAxa) {
          views[2].views.splice(2, 1);
        }
        if (!displayed.statisticsAllianz) {
          views[2].views.splice(1, 1);
        }
        if (!displayed.statisticsAbeille) {
          views[2].views.splice(0, 1);
        }
        
      }
      if (!this.props.admin && this.props.companyId) {
        views = [views[0], ...views.slice(2)];
      }
      this.sidebarRef.current.setState({ views: views });
    }
  }

  handleSignOutClick = () => {
    axiosConfig("/auth/signout").then((res) => {
      document.location.reload();
    });
  };

  getCompanyIcon = () => {
    let logo = localStorage.getItem("logoCompany");
    let company = localStorage.getItem("idCompany");
    if (!logo || parseInt(company) !== parseInt(this.context.company)) {
      axiosConfig({
        url: "/auth/get-logo",
        data: {
          companyId: this.context.companyId,
        },
      }).then((res) => {
        if (res.data.success === true) {
          localStorage.setItem("logoCompany", res.data.data);
          localStorage.setItem("idCompany", this.context.company);
          this.setState({ logo: res.data.data });
        } else {
          this.setState({ logo: null });
        }
      });
    } else if (logo) {
      this.setState({ logo });
    }
  };

  render() {
    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <Sidebar
            icon={Icon}
            picture={this.context.picture ? this.context.picture : null}
            ref={this.sidebarRef}
            location={this.props.location}
            onHide={(result) => this.setState({ sidebarReduce: result })}
            views={this.state.views}
            allowDisabledClick={true}
            onViewChange={(result) => {
              this.props.history.push(result.path);
            }}
            title={this.context.name}
            // subtitle={this.context.roleStr}
            subtitle={
              this.props.companyId === null
                ? "Administrateur Général"
                : this.props.admin
                ? "Administrateur"
                : ""
            }
          />
          <div className="Dashboard-container-body">
            <Header logo={this.state.logo} height="42">
              {this.state.sidebarMobile ? (
                <ButtonIcon
                  onClick={() => {
                    this.sidebarRef.current.handleHideClick();
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.props.history.push("/settings")}
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className="Dashboard-content">
              {this.props.connected === true ? this.props.children : ""}
            </div>
          </div>
        </div>
      );
    }
  }
}
