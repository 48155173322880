import React from "react";
import {
  Nav,
  Card,
  Button,
  Dropzone,
  FormInput,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

import "./admin-general.css";

export default class AdminGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: false,
      picture: null,
      preview: null,
      primaryColor: "#2b5876",
      secondaryColor: "#4e4376",
      // Tabs
      tabsMenu: [],
      tabsIndex: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      accessAllowed: checkRights("Administration", this.context),
    });
    if (this.context.companyId) {
      this.getCompanyData();
    } else {
      this.getAllCompanies();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tabsMenu !== this.state.tabsMenu) {
      this.getCompanyData();
    } else if (prevState.tabsIndex !== this.state.tabsIndex) {
      this.getCompanyData();
    }
  }

  getAllCompanies = () => {
    axiosConfig("admin/general/get-all-companies").then((res) => {
      if (res.data.success) {
        this.setState({ tabsMenu: res.data.data });
      } else {
        this.setState({ tabsMenu: [] });
      }
    });
  };

  getCompanyData = () => {
    axiosConfig({
      url: "admin/general/get-company",
      data: {
        companyId:
          this.state.tabsMenu.length > 0
            ? this.state.tabsMenu[this.state.tabsIndex].id
            : null,
      },
    }).then((res) => {
      if (res.data.success) {
        let access = {};
        let display = {};

        Object.keys(res.data.data.access.clickable).forEach((key) => {
          access[`${key}Access`] = res.data.data.access.clickable[key];
        });
        Object.keys(res.data.data.access.displayed).forEach((key) => {
          display[`${key}Display`] = res.data.data.access.displayed[key];
        });

        this.setState({
          picture: res.data.data.logo,
          primaryColor: res.data.data.interfaceColors.primary,
          secondaryColor: res.data.data.interfaceColors.secondary,
          ...access,
          ...display,
        });
      } else {
        this.setState({
          picture: null,
          primaryColor: "#2b5876",
          secondaryColor: "#4e4376",
        });
      }
    });
  };

  setBlobValue = (event) => {
    this.setState({
      dataBlob: event.base64,
      dataMime: event.mime,
      preview: event.base64,
    });
  };

  handleSaveLogoClick = () => {
    axiosConfig({
      url: "admin/general/update-logo",
      data: {
        companyId:
          this.state.tabsMenu.length > 0
            ? this.state.tabsMenu[this.state.tabsIndex].id
            : null,
        base64: this.state.dataBlob,
        mime: this.state.dataMime,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          picture: this.state.dataBlob,
          preview: null,
        });
        alert("Logo enregistré avec succès");
        if (this.context.companyId) document.location.reload();
      } else {
        alert("Erreur lors de la sauvegarde");
      }
    });
  };

  handleColorInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleResetColors = () => {
    this.setState({
      primaryColor: "#2b5876",
      secondaryColor: "#4e4376",
    });
  };

  handleSaveColorClick = () => {
    axiosConfig({
      url: "admin/general/update-colors",
      data: {
        companyId:
          this.state.tabsMenu.length > 0
            ? this.state.tabsMenu[this.state.tabsIndex].id
            : null,
        colors: JSON.stringify({
          primary: this.state.primaryColor,
          secondary: this.state.secondaryColor,
        }),
      },
    }).then((res) => {
      if (res.data.success) {
        alert("Nouvelles couleurs sauvegardées avec succès");
        this.getCompanyData();
        if (this.context.companyId) document.location.reload();
      } else {
        alert("Erreur lors de la sauvegarde");
      }
    });
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked }, () => {
      if (
        checked === false &&
        (name === "glassDisplay" ||
          name === "statisticsGeneralDisplay" ||
          name === "statisticsAbeilleDisplay" ||
          name === "statisticsAllianzDisplay" ||
          name === "statisticsAllianzOMDisplay" ||
          name === "statisticsGarageDisplay" ||
          name === "statisticsAxaDisplay" ||
          name === "selfcareDisplay" ||
          name === "auditDisplay" ||
          name === "anomalyDisplay" ||
          name === "missionsDisplay" ||
          name === "egaDisplay" ||
          name === "caravaningDisplay" ||
          name === "electricDisplay" ||
          name === "rankingDisplay" ||
          name === "statisticsCoveaDisplay")
      ) {
        switch (name) {
          case "glassDisplay":
            this.setState({ glassAccess: false });
            break;
          case "statisticsGeneralDisplay":
            this.setState({ statisticsGeneralAccess: false });
            break;
          case "statisticsAbeilleDisplay":
            this.setState({ statisticsAbeilleAccess: false });
            break;
          case "statisticsAllianzDisplay":
            this.setState({ statisticsAllianzAccess: false });
            break;
          case "statisticsAllianzOMDisplay":
            this.setState({ statisticsAllianzOMAccess: false });
            break;
          case "statisticsGarageDisplay":
            this.setState({ statisticsGarageAccess: false });
            break;
          case "statisticsAxaDisplay":
            this.setState({ statisticsAxaAccess: false });
            break;
          case "selfcareDisplay":
            this.setState({ selfcareAccess: false });
            break;
          case "auditDisplay":
            this.setState({ auditAccess: false });
            break;
          case "anomalyDisplay":
            this.setState({ anomalyAccess: false });
            break;
          case "missionsDisplay":
            this.setState({ missionsAccess: false });
            break;
          case "egaDisplay":
            this.setState({ egaAccess: false });
            break;
          case "caravaningDisplay":
            this.setState({ caravaningAccess: false });
            break;
          case "electricDisplay":
            this.setState({ electricAccess: false });
            break;
          case "rankingDisplay":
            this.setState({ rankingAccess: false });
          case "statisticsCoveaDisplay":
            this.setState({ statisticsCoveaAccess: false });
          default:
            break;
        }
      } else if (
        checked === true &&
        (name === "glassAccess" ||
          name === "statisticsGeneralAccess" ||
          name === "statisticsAbeilleAccess" ||
          name === "statisticsAllianzAccess" ||
          name === "statisticsAllianzOMAccess" ||
          name === "statisticsGarageAccess" ||
          name === "statisticsAxaAccess" ||
          name === "selfcareAccess" ||
          name === "auditAccess" ||
          name === "anomalyAccess" ||
          name === "missionsAccess" ||
          name === "egaAccess" ||
          name === "caravaningAccess" ||
          name === "electricAccess" ||
          name === "rankingAccess" ||
          name === "statisticsCoveaAccess")
      ) {
        switch (name) {
          case "glassAccess":
            this.setState({ glassDisplay: true });
            break;
          case "statisticsGeneralAccess":
            this.setState({ statisticsGeneralDisplay: true });
            break;
          case "statisticsAbeilleAccess":
            this.setState({ statisticsAbeilleDisplay: true });
            break;
          case "statisticsAllianzAccess":
            this.setState({ statisticsAllianzDisplay: true });
            break;
          case "statisticsAllianzOMAccess":
            this.setState({ statisticsAllianzOMDisplay: true });
            break;
          case "statisticsGarageAccess":
            this.setState({ statisticsGarageDisplay: true });
            break;
          case "statisticsAxaAccess":
            this.setState({ statisticsAxaDisplay: true });
            break;
          case "selfcareAccess":
            this.setState({ selfcareDisplay: true });
            break;
          case "auditAccess":
            this.setState({ auditDisplay: true });
            break;
          case "anomalyAccess":
            this.setState({ anomalyDisplay: true });
            break;
          case "missionsAccess":
            this.setState({ missionsDisplay: true });
            break;
          case "egaAccess":
            this.setState({ egaDisplay: true });
            break;
          case "caravaningAccess":
            this.setState({ caravaningDisplay: true });
            break;
          case "electricAccess":
            this.setState({ electricDisplay: true });
            break;
          case "rankingAccess":
            this.setState({ rankingDisplay: true });
          case "statisticsCoveaAccess":
            this.setState({ statisticsCoveaDisplay: true });
            break;
          default:
            break;
        }
      }
    });
  };

  handleEditAccessClick = () => {
    let accessTmp = {};
    let displayTmp = {};

    Object.keys(this.state).forEach((key) => {
      if (key.includes("Access")) {
        accessTmp[key.replace("Access", "")] = this.state[key];
      } else if (key.includes("Display")) {
        displayTmp[key.replace("Display", "")] = this.state[key];
      }
    });

    const access = JSON.stringify({
      clickable: accessTmp,
      displayed: displayTmp,
    });

    axiosConfig({
      url: "admin/general/update-access",
      data: {
        companyId: this.state.tabsMenu[this.state.tabsIndex].id,
        access: access,
      },
    }).then((res) => {
      if (res.data.success) {
        alert("Droits d'accès sauvegardés avec succès");
        this.getCompanyData();
      } else {
        alert("Erreur lors de la sauvegarde");
      }
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <h2>
          Oups... Vous n'avez pas les autorisations nécessaires pour accéder à
          cette page...
        </h2>
      ) : (
        <>
          <FadeIn>
            <Nav title="Général" className="mb-20" />

            {this.context.companyId ? (
              <Row>
                {/* UPDATE LOGO */}
                <Col xs={12} md={6}>
                  <Card title="Logo" className="mb-20">
                    <div className="container">
                      {this.state.picture ? (
                        <img
                          style={{ width: "200px", position: "relative" }}
                          src={this.state.picture}
                          alt="logo"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faBuilding} size="6x" />
                      )}
                      <Dropzone
                        className={"m-auto my-10"}
                        dropHandler={this.setBlobValue}
                        typeName={["png"]}
                        typeAccept={["image/png"]}
                      />
                      {this.state.dataBlob ? (
                        <>
                          {this.state.preview ? (
                            <div>
                              <h4>Aperçu : </h4>
                              <img
                                style={{ width: "100px", position: "relative" }}
                                src={this.state.preview}
                                alt="logo"
                              />
                            </div>
                          ) : null}
                          <Button
                            text="Valider"
                            onClick={this.handleSaveLogoClick}
                          />
                        </>
                      ) : null}
                    </div>
                  </Card>
                </Col>

                {/* EDIT COLORS */}
                <Col xs={12} md={6}>
                  <Card title="Couleurs" className="mb-20">
                    <Row>
                      <Col xs={12} md={6}>
                        <FormInput
                          className="mb-20"
                          type="color"
                          name="primaryColor"
                          value={this.state.primaryColor}
                          title="Couleur primaire"
                          onChange={this.handleColorInputChange}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <FormInput
                          className="mb-20"
                          type="color"
                          name="secondaryColor"
                          value={this.state.secondaryColor}
                          title="Couleur secondaire"
                          onChange={this.handleColorInputChange}
                        />
                      </Col>
                    </Row>
                    <div style={{ display: "flex", marginLeft: "auto" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          className="button-color"
                          style={{
                            backgroundColor: this.state.primaryColor,
                            borderColor: this.state.primaryColor,
                          }}
                          onClick={this.handleSaveColorClick}
                        >
                          <span>Modifier la couleur</span>
                          <div
                            style={{
                              backgroundColor: this.state.secondaryColor,
                            }}
                          >
                            <FontAwesomeIcon icon={faCircle} size="lg" />
                          </div>
                        </button>
                        <span
                          className="reset-colors"
                          style={{ color: this.state.primaryColor }}
                          onClick={this.handleResetColors}
                        >
                          Couleurs par défaut
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            ) : (
              <TabsCustom>
                {/* COMPANY SELECT */}
                <TabsCustomHeader>
                  {this.state.tabsMenu.map((menu, index) => (
                    <TabsCustomMenu
                      key={index}
                      title={menu.name}
                      active={this.state.tabsIndex === index}
                      onClick={() => this.setState({ tabsIndex: index })}
                    />
                  ))}
                </TabsCustomHeader>

                {/* COMPANY EDIT */}
                <TabsCustomBody noCard>
                  {this.state.tabsMenu.map((element, idx) => (
                    <TabsCustomItem
                      key={idx}
                      active={this.state.tabsIndex === idx}
                    >
                      <Row>
                        {/* UPDATE LOGO */}
                        <Col xs={12} md={6}>
                          <Card title="Logo" className="mb-20">
                            <div className="container">
                              {this.state.picture ? (
                                <img
                                  style={{
                                    width: "200px",
                                    position: "relative",
                                  }}
                                  src={this.state.picture}
                                  alt="logo"
                                />
                              ) : (
                                <FontAwesomeIcon icon={faBuilding} size="6x" />
                              )}
                              <Dropzone
                                className={"m-auto my-10"}
                                dropHandler={this.setBlobValue}
                                typeName={["png"]}
                                typeAccept={["image/png"]}
                              />
                              {this.state.dataBlob ? (
                                <>
                                  {this.state.preview ? (
                                    <div>
                                      <h4>Aperçu : </h4>
                                      <img
                                        style={{
                                          width: "100px",
                                          position: "relative",
                                          borderRadius: "50%",
                                        }}
                                        src={this.state.preview}
                                        alt="logo"
                                      />
                                    </div>
                                  ) : null}
                                  <Button
                                    text="Valider"
                                    onClick={this.handleSaveLogoClick}
                                  />
                                </>
                              ) : null}
                            </div>
                          </Card>
                        </Col>

                        {/* EDIT COLORS */}
                        <Col xs={12} md={6}>
                          <Card title="Couleurs" className="mb-20">
                            <Row>
                              <Col xs={12} md={6}>
                                <FormInput
                                  className="mb-20"
                                  type="color"
                                  name="primaryColor"
                                  value={this.state.primaryColor}
                                  title="Couleur primaire"
                                  onChange={this.handleColorInputChange}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormInput
                                  className="mb-20"
                                  type="color"
                                  name="secondaryColor"
                                  value={this.state.secondaryColor}
                                  title="Couleur secondaire"
                                  onChange={this.handleColorInputChange}
                                />
                              </Col>
                            </Row>
                            <div
                              style={{ display: "flex", marginLeft: "auto" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "auto",
                                }}
                              >
                                <button
                                  className="button-color"
                                  style={{
                                    backgroundColor: this.state.primaryColor,
                                    borderColor: this.state.primaryColor,
                                  }}
                                  onClick={this.handleSaveColorClick}
                                >
                                  <span>Modifier la couleur</span>
                                  <div
                                    style={{
                                      backgroundColor:
                                        this.state.secondaryColor,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      size="lg"
                                    />
                                  </div>
                                </button>
                                <span
                                  className="reset-colors"
                                  style={{ color: this.state.primaryColor }}
                                  onClick={this.handleResetColors}
                                >
                                  Couleurs par défaut
                                </span>
                              </div>
                            </div>
                          </Card>
                        </Col>

                        {/* UPDATE COMPANY ACCESS DISPLAY */}
                        <Col xs={12} md={6}>
                          <Card title="Affichage" className="mb-20">
                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsGeneralDisplay"
                                    checked={
                                      this.state.statisticsGeneralDisplay
                                    }
                                    text="Statistiques GÉNÉRALE"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAbeilleDisplay"
                                    checked={
                                      this.state.statisticsAbeilleDisplay
                                    }
                                    text="Statistiques ABEILLE"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAllianzDisplay"
                                    checked={
                                      this.state.statisticsAllianzDisplay
                                    }
                                    text="Statistiques ALLIANZ"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAllianzOMDisplay"
                                    checked={
                                      this.state.statisticsAllianzOMDisplay
                                    }
                                    text="Statistiques ALLIANZ OUTRE-MER"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsGarageDisplay"
                                    checked={this.state.statisticsGarageDisplay}
                                    text="Suivi réparateur"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAxaDisplay"
                                    checked={this.state.statisticsAxaDisplay}
                                    text="Statistiques AXA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="glassDisplay"
                                    checked={this.state.glassDisplay}
                                    text="Bris de glace"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="selfcareDisplay"
                                    checked={this.state.selfcareDisplay}
                                    text="Selfcare"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="auditDisplay"
                                    checked={this.state.auditDisplay}
                                    text="Audit"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                              </Col>
                              <Col xs={12} md={6}>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="anomalyDisplay"
                                    checked={this.state.anomalyDisplay}
                                    text="Anomalie"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="missionsDisplay"
                                    checked={this.state.missionsDisplay}
                                    text="Répartition missions"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="egaDisplay"
                                    checked={this.state.egaDisplay}
                                    text="EGA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="caravaningDisplay"
                                    checked={this.state.caravaningDisplay}
                                    text="Caravaning"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="electricDisplay"
                                    checked={this.state.electricDisplay}
                                    text="Électrique"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="rankingDisplay"
                                    checked={this.state.rankingDisplay}
                                    text="Classement"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsCoveaDisplay"
                                    checked={this.state.statisticsCoveaDisplay}
                                    text="Statistiques COVEA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                              </Col>
                            </Row>
                            <Button
                              className="mb-4"
                              text={"Modifier"}
                              onClick={this.handleEditAccessClick}
                            />
                          </Card>
                        </Col>

                        {/* UPDATE COMPANY ACCESS */}
                        <Col xs={12} md={6}>
                          <Card title="Accès" className="mb-20">
                            <Row>
                              <Col xs={12} md={6}>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsGeneralAccess"
                                    checked={this.state.statisticsGeneralAccess}
                                    text="Statistiques GÉNÉRALE"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAbeilleAccess"
                                    checked={this.state.statisticsAbeilleAccess}
                                    text="Statistiques ABEILLE"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAllianzAccess"
                                    checked={this.state.statisticsAllianzAccess}
                                    text="Statistiques ALLIANZ"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAllianzOMAccess"
                                    checked={
                                      this.state.statisticsAllianzOMAccess
                                    }
                                    text="Statistiques ALLIANZ OUTRE-MER"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsGarageAccess"
                                    checked={
                                      this.state.statisticsGarageAccess
                                    }
                                    text="Suivi réparateur"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsAxaAccess"
                                    checked={this.state.statisticsAxaAccess}
                                    text="Statistiques AXA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="glassAccess"
                                    checked={this.state.glassAccess}
                                    text="Bris de glace"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="selfcareAccess"
                                    checked={this.state.selfcareAccess}
                                    text="Selfcare"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="auditAccess"
                                    checked={this.state.auditAccess}
                                    text="Audit"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                              </Col>
                              <Col xs={12} md={6}>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="anomalyAccess"
                                    checked={this.state.anomalyAccess}
                                    text="Anomalie"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="missionsAccess"
                                    checked={this.state.missionsAccess}
                                    text="Répartition missions"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="egaAccess"
                                    checked={this.state.egaAccess}
                                    text="EGA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="caravaningAccess"
                                    checked={this.state.caravaningAccess}
                                    text="Caravaning"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="electricAccess"
                                    checked={this.state.electricAccess}
                                    text="Électrique"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="rankingAccess"
                                    checked={this.state.rankingAccess}
                                    text="Classement"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                                <Row>
                                  <FormCheckbox
                                    className="mb-20"
                                    name="statisticsCoveaAccess"
                                    checked={this.state.statisticsCoveaAccess}
                                    text="Statistiques COVEA"
                                    onChange={this.handleCheckboxChange}
                                  />
                                </Row>
                              </Col>
                            </Row>
                            <Button
                              className="mb-4"
                              text={"Modifier"}
                              onClick={this.handleEditAccessClick}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </TabsCustomItem>
                  ))}
                </TabsCustomBody>
              </TabsCustom>
            )}
          </FadeIn>
        </>
      );
    } else {
      return <></>;
    }
  }
}
