import axiosConfig from "./axiosConfig";

function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  if (lat1 === null || lat2 === null || lon1 === null || lon2 === null) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

function getUsers(
  network,
  group,
  region,
  agency,
  userType,
  notAllUsers,
  callback,
  manageAgency = ""
) {
  let userOptions = [];
  if (!notAllUsers)
    userOptions.push({ value: 0, text: "Tous les utilisateurs" });

  axiosConfig({
    url: "/general/get-users",
    data: {
      network,
      group,
      region,
      agency,
      userType,
      manageAgency,
    },
  }).then((res) => {
    if (res.data.users) res.data.users.map((elem) => userOptions.push(elem));
    callback(userOptions);
  });
}

function getCompanies(callback, type = true) {
  let companyOptions = [];
  if (type === true) {
    companyOptions.push({ value: 0, text: "Toutes les compagnies" });
  }
  axiosConfig({
    url: "/general/get-companies",
  }).then((res) => {
    if (res.data.success === true) {
      companyOptions = companyOptions.concat(res.data.companies);
    }
    callback(companyOptions);
  });
}

function getGroups(callback, type = true) {
  let groupsOptions = [];
  if (type === true) {
    groupsOptions.push({ value: 0, text: "Tous les groupes" });
  }
  axiosConfig({
    url: "/general/get-groups",
  }).then((res) => {
    if (res.data.success === true) {
      groupsOptions = groupsOptions.concat(res.data.groups);
    }
    callback(groupsOptions);
  });
}

function getRegions(callback, type = true, group = null) {
  let regionsOptions = [];
  if (type === true) {
    regionsOptions.push({ value: 0, text: "Toutes les regions" });
  }
  axiosConfig({
    url: "/general/get-regions",
    data: {
      group: group,
    },
  }).then((res) => {
    if (res.data.success === true) {
      regionsOptions = regionsOptions.concat(res.data.regions);
    }
    callback(regionsOptions);
  });
}

function getAgencies(callback, type = true, region = null) {
  let agenciesOptions = [];
  if (type === true) {
    agenciesOptions.push({ value: 0, text: "Toutes les agences" });
  }
  axiosConfig({
    url: "/general/get-agencies-with-region",
    data: {
      region: region,
    },
  }).then((res) => {
    if (res.data.success === true) {
      agenciesOptions = agenciesOptions.concat(res.data.agencies);
      callback(agenciesOptions);
    } else {
      callback([]);
    }
  });
}

function addAlert(alerts, type, text) {
  switch (type) {
    case 1:
      alerts.push({
        text: text,
        backgroundColor: "bg-green-light",
        textColor: "green",
      });
      break;
    case 2:
      alerts.push({
        text: text,
        backgroundColor: "bg-red-light",
        textColor: "red",
      });
      break;
    default:
      alerts.push({
        text: text,
      });
      break;
  }
  return alerts;
}

function deleteAlert(alerts, id) {
  alerts.splice(id, 1);
  return alerts;
}

function getValue(value, digits = 2) {
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", options).format(value);
}

function getCurrencyValue(value, digits = 2) {
  const optionsCurrency = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsCurrency).format(value);
}

function getPercentageValue(value, digits = 2) {
  const optionsPercentage = {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsPercentage).format(value / 100);
}

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const getNameTypePerformance = (type) => {
  switch (type) {
    case 1:
      return "EXPERT TERRAIN";
    case 2:
      return "EXPERT EAD";
    case 3:
      return "ASSISTANT(E)";
    default:
      return "";
  }
};

const getUserTypePerformance = (type) => {
  switch (type) {
    case 1:
      return [2];
    case 2:
      return [3];
    case 3:
      return [4, 5];
    default:
      return [];
  }
};

const getIndex = (data, value, key = "id") => {
  value = value !== null ? value.toString() : "";
  var index = data.findIndex((item) => {
    if (item[key] && Array.isArray(item[key])) {
      var subIndex = item[key].findIndex((subItem) => {
        if (subItem.startsWith("%")) {
          return value.endsWith(subItem.replace("%", ""));
        } else if (subItem.endsWith("%")) {
          return value.startsWith(subItem.replace("%", ""));
        } else {
          return value === subItem;
        }
      });
      return subIndex >= 0;
    } else {
      return item[key] === value;
    }
  });

  return index;
};

const getArrayAverage = (array, decimal = null, multiplier = null) => {
  if (array) {
    let sum = 0;
    let length = 0;

    array.forEach((element) => {
      if (element !== null) {
        length++;
        sum += element;
      }
    });

    let result = sum / length || 0;

    if (multiplier) {
      result = result * multiplier;
    }

    if (!decimal) {
      return Math.round(result);
    } else {
      return result.toFixed(decimal);
    }
  } else {
    return null;
  }
};

export {
  distance,
  getUsers,
  getCompanies,
  getAgencies,
  addAlert,
  deleteAlert,
  getValue,
  getCurrencyValue,
  getPercentageValue,
  getGroups,
  getRegions,
  months,
  getNameTypePerformance,
  getUserTypePerformance,
  getIndex,
  getArrayAverage,
};
